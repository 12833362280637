







































import { Getter, Action } from "vuex-class";
import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import { FormBase, FormInput, FormSelect, FormError, FormSubmit, FormSingleImageInput, FormSingleFileInput, FormTag } from "@/components/forms";
import axios from "axios";

@Component({
  components: {
    LayoutModal: () => import("@/layouts/components/modal.vue"),
    SourceUrl: () => import("@/components/general/source-url.vue"),
    FormBase,
    FormInput,
    FormSelect,
    FormTag,
    FormError,
    FormSubmit,
    FormSingleImageInput,
    FormSingleFileInput,
  },
})
export default class ModalDotCreate extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Action("dot/create") createDot!: (payload: DotPayload) => Promise<Dot>;

  @Prop() open!: boolean;

  @Prop() hunchId?: number;

  @Ref() form!: FormClass;

  loadingContent = false;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: DotPayload = {
    title: "",
    arena_level: NaN,
    tags: [],
    workspace_id: NaN,
    hunch_ids: [],
  };

  private async scanUrl() {
    this.loadingContent = true;

    if (this.payload.link && this.payload.link.includes("youtu")) {
      this.loadingContent = false;
      this.payload.outline = {
        title: null,
        keywords: null,
        article_url: this.payload.link,
        html: null,
        site_name: "youtube",
        read_time: null,
        language: null,
        domain: "youtube.com",
        date: null,
        author: null,
        image: "",
      };
      return;
    }
    if (this.payload.link && this.payload.link.includes("vimeo")) {
      this.loadingContent = false;
      this.payload.outline = {
        title: null,
        keywords: null,
        article_url: this.payload.link,
        html: null,
        site_name: "vimeo",
        read_time: null,
        language: null,
        domain: "vimeo.com",
        date: null,
        author: null,
        image: "",
      };
      return;
    }

    const outline: Outline = await this.generateOutline();

    this.payload.outline = outline;

    if (outline && typeof outline.meta === "string") {
      this.payload.outline.meta = JSON.parse(outline.meta);
    }

    const potentialImages: string[] = [];

    if (outline.meta && outline.meta.microdata && outline.meta.microdata.image && Array.isArray(outline.meta.microdata.image)) {
      potentialImages.push(outline.meta.microdata.image[0]);
    } else if (outline.meta && outline.meta.microdata && outline.meta.microdata.image && !Array.isArray(outline.meta.microdata.image) && outline.meta.microdata.image.url) {
      potentialImages.push(outline.meta.microdata.image.url);
    } else if (outline.meta && outline.meta.thumbnail) {
      potentialImages.push(outline.meta.thumbnail);
    } else if (outline.meta && outline.meta.og && outline.meta.og["og:image"]) {
      potentialImages.push(outline.meta.og["og:image"]);
    } else if (outline.meta && outline.meta["msapplication-TileImage"]) {
      potentialImages.push(outline.meta["msapplication-TileImage"]);
    }

    if (potentialImages.length > 0) {
      this.payload.outline.image = potentialImages[0];
    }

    if (outline && outline.title) {
      this.payload.title = outline.title;
    }

    if (this.payload.outline.image) {
      try {
        this.payload.image = await this.downloadImage(this.payload.outline.image);
      } catch (e) {
        console.log(potentialImages);
        console.log(e);
      }
    }

    this.loadingContent = false;
  }

  private async submit() {
    this.payload.workspace_id = this.workspace.id;
    let media: MediaPayload | undefined = undefined;
    if (this.payload.media) {
      // @ts-ignore
      media = this.payload.media;
    }

    if (!this.payload.media && this.payload.link) {
      media = { path: this.payload.link, external: 1, name: `${this.payload.title}`, type: "link" };
    }

    if (this.workspace.is_basic) {
      this.payload.arena_level = 1;
    }

    if (this.hunchId) {
      this.payload.hunch_ids = [this.hunchId];
    }

    this.createDot({ ...this.payload, media: media })
      .then((dot: Dot) => {
        this.$router.push({ name: "dots-detail", params: { id: `${dot.id}` } });
        this.$emit("close");
      })
      .catch((errorResponse: ErrorResponse) => {
        console.log(errorResponse);
        this.errorResponse = errorResponse;
      });
  }

  private async generateOutline(): Promise<Outline> {
    return axios.get(`https://api.outline.com/v3/parse_article?source_url=${this.payload.link}`).then((res: { data: { data: Outline } }) => {
      return res.data.data;
    });
  }

  private async downloadImage(url: string): Promise<File> {
    return axios
      .get(url, {
        responseType: "blob",
      })
      .then((res: any) => {
        const blob: Blob = res.data;
        const name: string = Math.random().toString(36).substring(2);
        const file: File = new File([blob], name, {
          type: blob.type,
        });
        return file;
      });
  }

  get arenaLevels(): SelectOption[] {
    return [
      { id: 1, label: this.workspace.arena_level_1_title },
      { id: 2, label: this.workspace.arena_level_2_title },
      { id: 3, label: this.workspace.arena_level_3_title },
    ];
  }

  @Watch("open")
  handleOpen(open: boolean) {
    if (open) {
      this.payload = {
        title: "",
        arena_level: NaN,
        workspace_id: NaN,
      };
    }
  }
}
